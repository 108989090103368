import React, { useState } from "react";
import image_1 from "../assets/images/image_1.jpg";
import image_2 from "../assets/images/image_2.jpg";
import image_3 from "../assets/images/image_3.jpg";

const About = () => {
  const [about] = useState([
    {
      title: "Team Work",
      description:
        "We work as a team to ensure we give our clients the value they deserve",
      background: image_1,
    },
    {
      title: "Expertise",
      description:
        "We have the best men on our team with lots of experience and expertise in this field",
      background: image_2,
    },
    {
      title: "Office",
      description: "San Francisco, California",
      background: image_3,
    },
  ]);
  return (
    <section id="about">
      <div className="heading">
        <div className="heading-arrow"></div>
        <h2>About</h2>
      </div>
      <p className="main-color" data-aos="slide-up">
        WE ARE BEST DIGITAL AGENCY SINCE 2014
      </p>
      <div className="about">
        {about.map((details) => (
          <div
            className="details"
            style={{
              background: `url(${details.background}) center no-repeat`,
              backgroundSize: "cover",
            }}
          >
            <h2>{details.title}</h2>
            <p>{details.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default About;
