import React, { useEffect, useState } from "react";
import banner from "../assets/images/company_banner.png";
import { AiOutlineMenu } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [updated, setUpdated] = useState(false);
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    if (updated) return;
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    setUpdated(true);
  }, [updated]);
  return (
    <div className="header">
      <img src={banner} alt="StefanoCr4ck" />
      <div id="google_translate_element"></div>
      <ul>
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#services">Services</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#testimonials">Testimonials</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
        <li></li>
      </ul>
      <div className="toggle" onClick={() => setOpen(true)}>
        <AiOutlineMenu size={40} />
      </div>
      <div className={`sidebar ${open ? "active" : ""}`}>
        <h1
          onClick={() => setOpen(false)}
          className="cancel"
          style={{ color: "#fff" }}
        >
          <IoMdClose size={40} />
        </h1>
        <ul>
          {/* <li>
            <div id="google_translate_element"></div>
          </li> */}
          <li onClick={() => setOpen(false)}>
            <a href="#">Home</a>
          </li>
          <li onClick={() => setOpen(false)}>
            <a href="#services">Services</a>
          </li>
          <li onClick={() => setOpen(false)}>
            <a href="#about">About</a>
          </li>
          <li onClick={() => setOpen(false)}>
            <a href="#testimonials">Testimonials</a>
          </li>
          <li onClick={() => setOpen(false)}>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
