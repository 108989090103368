import React, { useState } from "react";

const Qualities = () => {
  const [qualities] = useState([
    {
      title: "High Quality Service",
      description:
        "Whenever we decide to work on any project, we ensure that we do it the best way possible. We do not let any factor limit us when working for our clients",
      aos: "slide-right",
    },
    {
      title: "Privacy",
      description:
        "We value the privacy of our clients so much. Unless asked to, we do not release private information of any client. We ensure that our clients are perfectly anonymous.",
      aos: "zoom-in-up",
    },
    {
      title: "Outstanding Resource Base",
      description:
        "Our team and assets are structured to keep up with changing technological standards in order to enable us create information worldwide.",
      aos: "slide-left",
    },
  ]);

  return (
    <aside className="qualities">
      {qualities.map((quality, index) => (
        <div className={`quality ${index === 1 && "bordered"}`}>
          <h2>{quality.title}</h2>
          <div className="line"></div>
          <p data-aos="zoom-in-up">{quality.description}</p>
        </div>
      ))}
    </aside>
  );
};

export default Qualities;
