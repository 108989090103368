import React, { useState, useEffect } from "react";
import marcus from "../assets/images/marcus.webp";
import patt from "../assets/images/patt.jpg";
import sophia from "../assets/images/sophia.jpg";
import carolyn from "../assets/images/carolyn.webp";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const Testimonials = () => {
  const [testimonials] = useState([
    {
      name: "Carolyn Frederick",
      role: "Events organiser",
      review:
        "My Facebook, Instagram and Gmail where compromised and a hacker was using this to extort my clients but when I came to Stefano Cr4ck they helped me recover access to all accounts.",
      image: carolyn,
    },
    {
      name: "Sophia Sanders",
      role: "Game Tester",
      review:
        "My gaming accounts where locked and all my credits where stolen but Stefano helped me get them back and even reactivated my gaming account. Thanks guys",
      image: sophia,
    },
    {
      name: "Marcus Kane",
      role: "Jewelry maker",
      review:
        "I got framed up recently and the information was all over the internet even when the issue was rectified I couldn’t erase the false info spreading all over the internet but Stefano Cr4ck helped me clean up all bad record of me on the internet.",
      image: marcus,
    },
    {
      name: "Gerald Pratt",
      role: "Driving instructor",
      review:
        "Recently a client scammed me and stole my crypto funds but Stefano Cr4ck helped me recover it.",
      image: patt,
    },
  ]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 520px)");
    setIsMobile(mediaQuery.matches);
    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);
  return (
    <section id="testimonials">
      <div className="heading">
        <div className="heading-arrow"></div>
        <h2>Testimonials</h2>
      </div>
      <p className="main-color" data-aos="slide-up">
        FEEDBACK FROM OUR CLIENTS
      </p>
      <div className="testimonials">
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={testimonials.length}
          isPlaying={true}
          // interval={5000}
          infinite={true}
          visibleSlides={isMobile ? 1 : 3}
          // stopOnHover={true}
        >
          <Slider>
            {testimonials.map((testimony, index) => (
              <Slide index={index}>
                <div className="testimony">
                  <div className="image">
                    <img src={testimony.image} alt="" />
                  </div>
                  <h3 className="name">{testimony.name}</h3>
                  <h4 className="role">{testimony.role}</h4>
                  <p>"{testimony.review}"</p>
                </div>
              </Slide>
            ))}
          </Slider>
          {/* <ButtonBack>Back</ButtonBack> */}
          {/* <ButtonNext>Next</ButtonNext> */}
        </CarouselProvider>
      </div>
    </section>
  );
};

export default Testimonials;
