import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";

const Services = () => {
  const [services] = useState([
    {
      name: "Cryptocurrency Recovery",
      description:
        "Lost access to crypto coins? you are one step close to recovering it.",
      icon: <FaIcons.FaBitcoin />,
    },
    {
      name: "Social Media Recovery",
      description:
        "Solutions are readily available because we know how important a social media account is to you.",
      icon: <FaIcons.FaTwitter />,
    },
    {
      name: "Scores and Record",
      description:
        "Credit score improvement and criminal record expungement on background check.",
      icon: <FaIcons.FaStickyNote />,
    },
    {
      name: "Blackmail or Extortion",
      description:
        "Victims of Sextortion, Blackmail, extortion, Romance scam, Recovering lost or stolen money etc.",
      icon: <FaIcons.FaUserShield />,
    },
    {
      name: "Account Activation",
      description:
        "Reactivation of deactivated account , Instacart, Amazon flex, door dash, Uber eats, Uber driver, Shipt , GrubHub, Postmates, esty and eBay etc.",
      icon: <FaIcons.FaAmazon />,
    },
    {
      name: "Device Hack",
      description:
        "How to hack into any device, computer, phone, iphone, deleting and Recovering lost files from devices etc.",
      icon: <FaIcons.FaApple />,
    },
  ]);

  function copy(address) {
    window.navigator.clipboard.writeText(address);
    return alert(`Copied ${address} to clipboard`);
  }
  return (
    <section id="services">
      <div className="heading">
        <div className="heading-arrow"></div>
        <h2>Services</h2>
      </div>
      <p className="main-color" data-aos="slide-up">
        OUR SERVICES FOR CLIENTS
      </p>
      <div className="services">
        {services.map((service, index) => (
          <div className={`service ${index === 1 && "shaded"}`}>
            <h1>{service.icon}</h1>
            <h2>{service.name}</h2>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
      <div className="steps-services">
        <h2 className="main-color" data-aos="slide-up">
          GET STARTED IN 4 EASY STEPS
        </h2>
        <div className="steps">
          <div className="step">
            <h1>1</h1>
            <p>
              Contact us using any of our{" "}
              <a href="#contact">available channels</a>
            </p>
          </div>
          <div className="step">
            <h1>2</h1>
            <p>Tell us your problem and the solution you are looking for</p>
          </div>
          <div className="step">
            <h1>3</h1>
            <p>Agree on price and payment method</p>
          </div>
          <div className="step">
            <h1>4</h1>
            <p>You can pay using any of the methods below, Click to copy</p>
          </div>
        </div>
        <div className="addresses">
          <aside
            onClick={() => copy("bc1qfv804sxq7pmgc8v8jhc8udd7fdtx5wtvr7mnuw")}
          >
            <FaIcons.FaBitcoin size={30} />{" "}
            <div>
              BTC - bc1qfv804sxq7pmgc8v8jhc8udd7fdtx5wtvr7mnuw{" "}
              <FaIcons.FaCopy />
            </div>
          </aside>
          <aside
            onClick={() => copy("0xd35400188Fe798a81C0CF2AD658936C0bBDa45B0")}
          >
            <FaIcons.FaEthereum size={30} />{" "}
            <div>
              ETH - 0xd35400188Fe798a81C0CF2AD658936C0bBDa45B0{" "}
              <FaIcons.FaCopy />
            </div>
          </aside>
        </div>
      </div>
    </section>
  );
};

export default Services;
