import React, { useState } from "react";
import banner from "../assets/images/company_banner.png";
import * as FaIcons from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineLocationOn } from "react-icons/md";
// import { ImLocation2 } from "react-icons/im";
// import TranslatorWidget from "react-translate-widget";

const Footer = () => {
  const [services] = useState([
    "Cryptocurrency Recovery",
    "Social Media Hack",
    "Scores and Record",
    "Blackmail or Extortion",
    "Account Activation",
    "Device Hack",
  ]);
  const [contact] = useState([
    {
      title: "San Francisco, California",
      to: "#",
      icon: <MdOutlineLocationOn />,
    },
    // {
    //   title: "+1 (512) 299-8496",
    //   to: "#", //coming
    //   icon: <FiPhoneCall />,
    // },
    // +1 (917) 226-8791
    {
      title: "+1 (917) 208-4635",
      to: "https://wa.me/19172084635", //coming
      icon: <FaIcons.FaWhatsapp />,
    },
    {
      title: "stefanocr4ck@gmail.com",
      to: "mailto:stefanocr4ck@gmail.com",
      icon: <AiOutlineMail />,
    },

    {
      title: "stefano___cr4ck",
      to: "https://www.instagram.com/stefano___cr4ck?igsh=Y2xjYzdnNnJicnZq", //IG
      icon: <FaIcons.FaInstagram />,
    },

    {
      title: "stefanocr4ck",
      to: "https://t.me/StefanoCr4ck1", //TG
      icon: <FaIcons.FaTelegram />,
    },
  ]);
  const [quicklinks] = useState([
    {
      title: "Home",
      to: "#",
    },
    {
      title: "About Us",
      to: "#about",
    },
    {
      title: "Services",
      to: "#services",
    },
  ]);
  return (
    <footer id="contact">
      {/* <div className="heading">
        <div className="heading-arrow"></div>
        <h2>Contact</h2>
      </div> */}

      {/* <p className="main-color" data-aos="slide-up">
        GET IN TOUCH
      </p> */}
      <div className="footer-section">
        <div className="footer-about">
          <img src={banner} alt="StefanoCr4ck" />
          <p>
            We are a team of skilled Cyber Security Experts with immerse desire
            to assist people and businesses around the globe run their business
            without the fear of being scammed or having to get locked out of
            their accounts for whatever reason
          </p>
        </div>
        <div className="footer-normal-sub">
          <h3>Contact</h3>
          {contact.map((item) => (
            <div className="footer-list-item">
              <div className="bullet">{item.icon}</div>
              <div className="item">
                <a href={item.to}>{item.title}</a>
              </div>
            </div>
          ))}
        </div>
        <div className="footer-normal-sub">
          <h3>Services</h3>
          {services.map((service) => (
            <div className="footer-list-item">
              <div className="bullet">
                <FaIcons.FaChevronRight />
              </div>
              <div className="item">{service}</div>
            </div>
          ))}
        </div>
        <div className="footer-normal-sub">
          <h3>Quick Links</h3>
          {quicklinks.map((item) => (
            <div className="footer-list-item">
              <div className="bullet">
                <FaIcons.FaChevronRight />
              </div>
              <div className="item">
                <a href={item.to}>{item.title}</a>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <TranslatorWidget sourceLanguageCode="en" className="translator" /> */}
      {/* <div id="google_translate_element"></div> */}
      <p
        style={{
          borderTop: "1px solid #888",
          width: "100%",
          textAlign: "center",
          fontSize: "11px",
          opacity: "0.9",
          padding: "10px 0px",
          color: "#ddd",
          marginTop: "20px",
        }}
      >
        Copyright © 2014. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
