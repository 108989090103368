import React, { useState } from "react";
import { FaWhatsapp, FaInstagram, FaTelegram } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

const Hero = () => {
  const [letters] = useState(["S", "E", "R", "V", "I", "C", "E"]);
  const [open, setOpen] = useState(false);
  const [contact] = useState([
    {
      // +19172084635
      title: "+1 (917) 208-4635",
      to: "https://wa.me/19172084635", //coming
      icon: <FaWhatsapp size={35} />,
    },
    {
      title: "stefanocr4ck@gmail.com",
      to: "mailto:stefanocr4ck@gmail.com",
      icon: <AiOutlineMail size={35} />,
    },

    {
      title: "stefano___cr4ck",
      to: "https://www.instagram.com/stefano___cr4ck?igsh=Y2xjYzdnNnJicnZq", //IG
      icon: <FaInstagram size={35} />,
    },

    {
      title: "stefanocr4ck",
      to: "https://t.me/StefanoCr4ck1", //TG
      icon: <FaTelegram size={35} />,
    },
  ]);
  return (
    <section id="hero">
      <p className="hero-sub-heading" data-aos="slide-down">
        We are a team of
      </p>
      <div className="hero-main-heading" data-aos="zoom-in-up">
        Professional
        <p>
          {letters.map((letter) => (
            <div
              style={{ "animation-delay": `${Math.floor(Math.random() * 4)}s` }}
            >
              {letter}
            </div>
          ))}
        </p>
        Providers
      </div>
      <div
        className="hero-button"
        data-aos="slide-up"
        onClick={() => {
          if (window.tidioChatApi) {
            window.tidioChatApi.open();
          }
        }}
      >
        Get in touch
      </div>
      <div className="float-button-section">
        <div className="float-socials">
          {contact.map((item) => (
            <a href={item.to} target="_blank">
              <div className={`float-buttons  ${open ? "" : "inactive"}`}>
                {item.icon}
              </div>
            </a>
          ))}
        </div>
        <div className="float-control" onClick={() => setOpen(!open)}>
          <div className={`float-message ${open ? "inactive" : ""}`}>
            <p>Need help?</p>
            <h3>Chat with us</h3>
          </div>
          <div className="float-buttons">
            {open ? <IoMdClose size={35} /> : <FaWhatsapp size={35} />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
